<template>
  <div>
    <div class="pa-2 indigo">
      <span class="text-subtitle-1 font-weight-bold white--text"
        >媒体個別の設定</span
      >
    </div>
    <template v-if="shopSites.length">
      <v-tabs
        v-model="refOnlySiteTabs"
        style="border-bottom: 1px solid lightgray"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="shopSite in shopSites"
          :key="shopSite.id"
          class="font-weight-bold"
          >{{ shopSite.name }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="refOnlySiteTabs" touchless>
        <v-tab-item
          v-for="shopSite in shopSites"
          :key="shopSite.id"
          :eager="true"
        >
          <component
            v-bind:is="shopSite.component"
            ref="refShopSiteComponent"
            :com_data="shopSite"
          ></component>
        </v-tab-item>
      </v-tabs-items>

      <!-- <div v-for="shopSite in shopSites" :key="shopSite.id">
        <component
          v-bind:is="shopSite.component"
          ref="refShopSiteComponent"
          :com_data="shopSite"
        ></component>
      </div> -->
    </template>
    <div v-else>
      <v-card tile elevation="0" class="item-card pa-2">
        利用できる項目はありません。
      </v-card>
    </div>
    <v-card tile elevation="0" class="item-card pa-2">
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col cols="6" sm="3">
              <general-button btn_type="info" btn_block @click-event="onSubmit"
                ><template v-slot:icon
                  ><v-icon left>mdi-content-save</v-icon></template
                >保存</general-button
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <general-button btn_type="info" btn_block @click-event="onBack"
                ><template v-slot:icon
                  ><v-icon left>mdi-chevron-left</v-icon></template
                >前へ</general-button
              >
            </v-col>
            <v-col cols="3">
              <general-button btn_type="info" btn_block @click-event="onForward"
                ><template v-slot:icon
                  >次へ<v-icon right>mdi-chevron-right</v-icon></template
                ></general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";

export default defineComponent({
  props: ["cast_data", "cast_sync_site_update_ids"],
  setup(props, ctx) {
    // const ShopSitesRepository = repositoryFactory.get("shopSites");
    const refShopSiteComponent = ref();
    const refOnlySiteTabs = ref();
    const state = reactive({
      errorTab: null,
      errorCom: null,
      shopSites: [],
      baseComponents: {},
    });

    // 初期化処理
    const init = async () => {
      // onlySiteComponentフォルダのコンポーネントを取得する
      const files = require.context("./onlySiteComponent", true, /\.vue$/);
      files.keys().forEach((key) => {
        set(
          state.baseComponents,
          key.replace(/(\.\/|\.vue)/g, ""),
          files(key).default
        );
      });

      // 更新媒体で営業タイプの媒体を取得
      const tmpShopSite = store.getters["shop_sites/getShopSiteList"];
      Object.keys(tmpShopSite).forEach(function (key) {
        if (
          props.cast_sync_site_update_ids.includes(tmpShopSite[key].id) &&
          tmpShopSite[key].sites_type == setting.site_type_sales &&
          tmpShopSite[key].is_active &&
          tmpShopSite[key].sites_com_name in state.baseComponents
        ) {
          // 媒体毎のコンポーネントに渡すデータを生成
          const prefix =
            setting.cast_props_only +
            "__" +
            tmpShopSite[key].id +
            "__" +
            tmpShopSite[key].sites_com_name;
          const cast_prop = {};
          for (let key in props.cast_data.cast_prop) {
            if (!key.indexOf(prefix)) {
              const com_key = key.replace(prefix + "__", ""); // prefix部分をキーから消しておく
              cast_prop[com_key] = props.cast_data.cast_prop[key];
            }
          }
          // データを渡す
          state.shopSites.push({
            id: tmpShopSite[key].id,
            sites_id: tmpShopSite[key].sites_id,
            name: tmpShopSite[key].name,
            color: tmpShopSite[key].color,
            sites_com_name: tmpShopSite[key].sites_com_name,
            is_active: tmpShopSite[key].is_active,
            component: state.baseComponents[tmpShopSite[key].sites_com_name],
            cast_id: props.cast_data.cast_id,
            cast_prop: cast_prop,
            prefix: prefix,
          });
        }
      });
    };

    init();

    const onSubmit = async () => {
      // 親コンポーネントの保存処理に投げる
      ctx.emit("com_save");
    };

    // チェック処理（各媒体コンポーネントにチェック処理を投げる）
    const checkValid = async () => {
      if (state.shopSites.length == 0) return true;

      // 事前チェック処理
      state.errorCom = null;
      const max_index = refShopSiteComponent.value.length;
      const shopSiteCom = refShopSiteComponent.value;
      let result = true;
      for (let index = 0; index < max_index; index++) {
        result = await shopSiteCom[index].checkValid();
        if (!result) {
          refOnlySiteTabs.value = index;
          state.errorCom = refShopSiteComponent.value[index];
          return result;
        }
      }
      return result;
    };

    const setErrorFocus = async () => {
      await state.errorCom.setErrorFocus();
    };

    // 保存処理（各媒体コンポーネントにチェック処理を投げる）
    const save = async () => {
      if (state.shopSites.length == 0) return true;

      const max_index = refShopSiteComponent.value.length;
      const shopSiteCom = refShopSiteComponent.value;
      for (let index = 0; index < max_index; index++) {
        await shopSiteCom[index].save();
      }
      return true;
    };

    const onBack = () => {
      ctx.emit("tab_back");
    };
    const onForward = () => {
      ctx.emit("tab_forward");
    };

    return {
      setting,
      onSubmit,
      checkValid,
      setErrorFocus,
      save,
      refOnlySiteTabs,
      refShopSiteComponent,
      ...toRefs(state),
      onBack,
      onForward,
    };
  },
});
</script>

<style scoped>
.v-tab--active {
  background-color: #e8f5e9;
}
</style>
