var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-card',{staticClass:"item-card pa-2",attrs:{"tile":"","elevation":"0"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"pb-2 mb-2"},[_c('span',{},[_vm._v("全媒体共通で使用するコメントを設定します。媒体別にコメントを設定したい場合は、「"),_c('strong',[_vm._v("媒体個別項目")]),_vm._v("」でコメントを設定してください。")])]),_c('ValidationObserver',{ref:"observer"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("お店コメント")]),_c('item-required',{attrs:{"item_rules":_vm.itemRules,"item_key":"cast__basic__comment_by_shop"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('div',{staticClass:"mt-1 mb-1"},[_c('span',{staticClass:"font-weight-bold text-align-middle"},[_vm._v("テキスト用")])]),_c('v-tabs',{attrs:{"show-arrows":"","height":"32","hide-slider":true},model:{value:(_vm.refCommentByShopTab),callback:function ($$v) {_vm.refCommentByShopTab=$$v},expression:"refCommentByShopTab"}},_vm._l((_vm.commentByShopData),function(tabData){return _c('v-tab',{key:'bt' + tabData.index,class:("" + (tabData.value
                          ? 'font-weight-bold text-blue-important'
                          : '')),attrs:{"active-class":"blue lighten-5 font-weight-bold"}},[_vm._v(" "+_vm._s(tabData.name)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.refCommentByShopTab),callback:function ($$v) {_vm.refCommentByShopTab=$$v},expression:"refCommentByShopTab"}},_vm._l((_vm.commentByShopData),function(tabData){return _c('v-tab-item',{key:'bi' + tabData.index,staticClass:"blue lighten-5 pt-2",attrs:{"eager":true}},[_c('div',{staticClass:"ma-3 mb-0"},[_c('ValidationProvider',{attrs:{"name":_vm.items.cast__basic__comment_by_shop.text,"rules":'max:' + tabData.counter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('v-textarea',{attrs:{"name":tabData.el_name,"rules":tabData.rules,"counter":tabData.counter,"error-messages":errors,"success":valid,"hint":tabData.hint +
                              (_vm.itemRules.cast__basic__comment_by_shop.comment
                                .length > 0
                                ? '※' +
                                  _vm.itemRules.cast__basic__comment_by_shop
                                    .comment
                                : ''),"label":_vm.items.cast__basic__comment_by_shop.text,"rows":"8","persistent-hint":"","outlined":"","background-color":"white"},model:{value:(tabData.value),callback:function ($$v) {_vm.$set(tabData, "value", $$v)},expression:"tabData.value"}})]}}],null,true)})],1)])}),1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('div',{staticClass:"mt-1 mb-1"},[_c('span',{staticClass:"font-weight-bold text-align-middle"},[_vm._v("HTML用 ")]),_c('v-btn',{staticClass:"font-weight-bold pa-1 d-inline",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.onHtmlClear('shop')}}},[_vm._v("HTMLをクリア")])],1),_c('div',{},[_c('ckeditor-component',{ref:"refCommentByShopCkeditor",attrs:{"data":_vm.commentByShopDataHTML}})],1)])],1)],1)],1),_c('v-divider',{staticClass:"mt-8 mb-8"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("キャストコメント")]),_c('item-required',{attrs:{"item_rules":_vm.itemRules,"item_key":"cast__basic__comment_by_cast"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('div',{staticClass:"mt-1 mb-1"},[_c('span',{staticClass:"font-weight-bold text-align-middle"},[_vm._v("テキスト用")])]),_c('v-tabs',{attrs:{"show-arrows":"","height":"32","hide-slider":true},model:{value:(_vm.refCommentByCastTab),callback:function ($$v) {_vm.refCommentByCastTab=$$v},expression:"refCommentByCastTab"}},_vm._l((_vm.commentByCastData),function(tabData){return _c('v-tab',{key:'bt' + tabData.index,class:("" + (tabData.value
                          ? 'font-weight-bold text-blue-important'
                          : '')),attrs:{"active-class":"blue lighten-5 font-weight-bold"}},[_vm._v(" "+_vm._s(tabData.name)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.refCommentByCastTab),callback:function ($$v) {_vm.refCommentByCastTab=$$v},expression:"refCommentByCastTab"}},_vm._l((_vm.commentByCastData),function(tabData){return _c('v-tab-item',{key:'bi' + tabData.index,staticClass:"blue lighten-5 pt-2",attrs:{"eager":true}},[_c('div',{staticClass:"ma-3 mb-0"},[_c('ValidationProvider',{attrs:{"name":_vm.items.cast__basic__comment_by_cast.text,"rules":'max:' + tabData.counter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('v-textarea',{attrs:{"name":tabData.el_name,"rules":tabData.rules,"counter":tabData.counter,"error-messages":errors,"success":valid,"hint":tabData.hint +
                              (_vm.itemRules.cast__basic__comment_by_cast.comment
                                .length > 0
                                ? '※' +
                                  _vm.itemRules.cast__basic__comment_by_cast
                                    .comment
                                : ''),"label":_vm.items.cast__basic__comment_by_cast.text,"rows":"8","persistent-hint":"","outlined":"","background-color":"white"},model:{value:(tabData.value),callback:function ($$v) {_vm.$set(tabData, "value", $$v)},expression:"tabData.value"}})]}}],null,true)})],1)])}),1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('div',{staticClass:"mt-1 mb-1"},[_c('span',{staticClass:"font-weight-bold text-align-middle"},[_vm._v("HTML用 ")]),_c('v-btn',{staticClass:"font-weight-bold pa-1 d-inline",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.onHtmlClear('cast')}}},[_vm._v("HTMLをクリア")])],1),_c('div',{},[_c('ckeditor-component',{ref:"refCommentByCastCkeditor",attrs:{"data":_vm.commentByCastDataHTML}})],1)])],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":""},on:{"click-event":_vm.onSubmit},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")])]},proxy:true}])},[_vm._v("保存")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('general-button',{attrs:{"btn_type":"gray","btn_block":"","btn_disabled":true},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")])]},proxy:true}])},[_vm._v("前へ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":"","btn_disabled":_vm.cast_id == 0},on:{"click-event":_vm.onForward},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_vm._v("次へ"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2 cyan"},[_c('span',{staticClass:"text-subtitle-1 font-weight-bold white--text"},[_vm._v("全媒体共通の設定")])])}]

export { render, staticRenderFns }